// UI框架
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// UI组件
import { Row, Col, Form, Empty, Table, Switch, Popconfirm, Checkbox, Divider, message, Button } from 'antd';
import { Icon, PtzControl, LabelInput, LabelSelect, LabelSlider, LabelSwitch } from 'libs';
// 业务组件
import { bindLifecycle } from 'utils/bindLifecycle';
import { LangConsumer } from 'utils/LangProvider';
import { clone } from '../../../../common';
import _ from 'lodash';
import WebApp from '../../../../webapp';
import {lengthB} from 'utils/checkbit';
import {localWordTransArray, carLibraryTransMap} from '../stringMap';
// 样式
import './index.less';

const _name_ = 'VehicleRuleConfigForm';

class VehicleRuleConfigFormComponent extends React.Component {
    /** 用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = _name_;
        this.state = {};
    }

    componentDidMount () {}

    componentDidActivate () {}

    componentWillUnmount () {}

    showVehicleLibraryConfig () {
        let { goTo, history } = this.props;
        goTo({
            path: 'index/VehicleLibraryConfig',
            history
        });
    }

    /**
     * 点击“-”号减少跟踪目标大小比例
     */
    TrackingObjectSizeMinusClick = () => {
        if (this.props.form.getFieldValue('TrackScale') > 10) {
            this.props.form.setFieldsValue({ 'TrackScale': this.props.form.getFieldValue('TrackScale') - 1 });
        }
    }

    /**
     * 点击“+”号增加跟踪目标大小比例
     */
    TrackingObjectSizePlusClick = () => {
        if (this.props.form.getFieldValue('TrackScale') < 100) {
            this.props.form.setFieldsValue({ 'TrackScale': this.props.form.getFieldValue('TrackScale') + 1 });
        }
    }
    render () {
        let {$t, form, colWidth, supportDB, lang, ruleConfig, langlocale, currentChannel} = this.props;
        const { getFieldDecorator } = form;
        let carLibraryOptions = [];
        if (supportDB instanceof Array && supportDB.length > 0) {
            for (let i = 0; i < supportDB.length; i++) {
                carLibraryOptions.push({text : $t(carLibraryTransMap[supportDB[i]]), value : supportDB[i]});
            }
        }
        let localWordOptions = [];
        for (let i = 0; i < localWordTransArray.length; i++) {
            localWordOptions.push({value : $t(`${localWordTransArray[i]}`), text : $t(`${localWordTransArray[i]}`)});
        }
        const englishColWidth = [{span : 6, xxl : 6}, {span : 9, xxl : 9}, {span : 6, xxl : 6, offset : 0}];
        let trackSliderColWidth = (langlocale.locale === 'zh-cn') ? [{span : 6, xxl : 4}, {span : 7, xxl : 7}, {span : 5, xxl : 5, offset : 0}] : englishColWidth;
        return (
            <div className={`_${_name_}_`}>
                <Form labelAlign={'left'}>
                    <Form.Item>
                        <Button onClick={() => this.showVehicleLibraryConfig()}>{$t('ivs.VehicleLibraryConfig')}</Button>
                    </Form.Item>
                    <Divider />
                    <Form.Item>
                        {getFieldDecorator('CarLibrary', {
                        })(
                            <LabelSelect
                                label={$t('ivs.VehicleLibrary')}
                                colWidth = {colWidth}
                                options = {carLibraryOptions}
                            />
                        )}
                    </Form.Item>
                    {lang === 'zh-cn' &&
                        <Form.Item>
                            {getFieldDecorator('LocalWord', {
                            })(
                                <LabelSelect
                                    label={$t('sys.LocalWord')}
                                    colWidth = {colWidth}
                                    options = {localWordOptions}
                                />
                            )}
                        </Form.Item>
                    }
                    <Divider />
                    <Form.Item>
                        {getFieldDecorator('AutoTrack', {
                            valuePropName: 'checked'
                        })(
                            <LabelSwitch
                                label = {$t(WebApp.ChannelTrackerCaps[currentChannel].CivilTrack ? 'ivs.TrackAlarmLite' : 'ivs.TrackAlarm')}
                                colWidth = {colWidth}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('TrackTime', {
                        })(
                            <LabelInput
                                label = {$t('ivs.TrackTime')}
                                colWidth = {colWidth}
                                type={'number'}
                                min={15}
                                max={300}
                                precision={0}
                                behind={
                                    <span>{$t('com.Second') + ' (15-300)'}</span>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('TrackScale', {
                        })(
                            <LabelSlider
                                showPlusAndMinus = {true}
                                colWidth = {trackSliderColWidth}
                                label = {$t('ivs.TrackingObjectSize')}
                                onMinusClick = {this.TrackingObjectSizeMinusClick}
                                onPlusClick = {this.TrackingObjectSizePlusClick}
                                max={100}
                                min={10}
                                sliderVal = {this.props.form.getFieldValue('TrackScale')}
                                sliderValCol={3}
                            />
                        )}
                    </Form.Item>
                    <Divider />
                </Form>
            </div>
        );
    }
}

let ruleForm = Form.create(
    {
        name: 'VehicleRuleConfigForm',
        mapPropsToFields (props) {
            let {ruleConfig, globalConfig, $t} = props;
            return {
                CarLibrary : Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.PlateRecordName
                }),
                LocalWord : Form.createFormField({
                    value:  globalConfig && globalConfig.Detail && (globalConfig.Detail.PlateHints === undefined || globalConfig.Detail.PlateHints.length === 0 ? $t('com.None') : globalConfig.Detail.PlateHints[0])
                }),
                AutoTrack : Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.VehicleDetectTrack && ruleConfig.Config.VehicleDetectTrack.Enable
                }),
                TrackTime : Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.VehicleDetectTrack && ruleConfig.Config.VehicleDetectTrack.TrackDuration
                }),
                TrackScale: Form.createFormField({
                    value: ruleConfig && ruleConfig.Config && ruleConfig.Config.VehicleDetectTrack && ruleConfig.Config.VehicleDetectTrack.TrackScale || 50
                })
            };
        },
        onFieldsChange (props, changedFields) {
            props.formChange(changedFields);
        }
    }
);

export default withRouter(LangConsumer(bindLifecycle(ruleForm(VehicleRuleConfigFormComponent))));